import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },

        {
            path: '/choose',
            name: 'Choose',
            component: () =>
            {
                return import( /* webpackChunkName: "choose" */ '@/app/pages/Choose' );
            }
        },

        {
            path: '/result',
            name: 'Result',
            component: () =>
            {
                return import( /* webpackChunkName: "result" */ '@/app/pages/Result' );
            }
        },

        {
            path: '/thanks',
            name: 'Thanks',
            component: () =>
            {
                return import( /* webpackChunkName: "thanks" */ '@/app/pages/Thanks' );
            }
        },

        {
            path: '/test',
            name: 'Test',
            component: () =>
            {
                return import( /* webpackChunkName: "test" */ '@/app/pages/Test' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;

import 'babel-polyfill';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import store from '@/app/store';
import VuexRouterSync from 'vuex-router-sync';

import Axios from 'axios';

// import VeeValidate from 'vee-validate';
// import dict from 'vee-validate/dist/locale/nl';
// import { i18n } from '@/app/config';

VuexRouterSync.sync( store, router );

// Vue.use( VeeValidate, {
//     events: 'change'
// });

// VeeValidate.Validator.localize( 'nl', Object.assign( dict, i18n.nl ) );

Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

Vue.config.productionTip = false;

setInterval( function()
{
    Vue.$http.get( window._rootData.apiUrl + '/ping' );
}, 10000 );

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});

<template>
    <div id="app">
        <ynk-layout>
            <transition
                mode="out-in"
                @enter="getAnimateIn"
                @leave="getAnimateOut"
                :css="false"
                appear>
                <router-view :key="$route.path" ref="currentView"></router-view>
            </transition>
        </ynk-layout>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { TimelineMax } from '@/vendor';
    import Main from '@/app/layouts/Main';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main
        },

        data()
        {
            return {
            };
        },

        computed: {
            prevRoute()
            {
                return this.$store.state.route.from;
            }
        },

        mounted()
        {
            this.routePath = [ 'Landing', 'Choose', 'Result', 'Thanks' ];

            // document.addEventListener( 'contextmenu', event => event.preventDefault(), false );

            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            getAnimateIn( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });

                if( this.routePath.indexOf( this.prevRoute.name ) < this.routePath.indexOf( this.$route.name ) )
                {
                    if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                    {
                        tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                    }
                }
                else
                {
                    if( typeof this.$refs.currentView.getAnimateInBack === 'function' )
                    {
                        tl.add( this.$refs.currentView.getAnimateInBack(), 0 );
                    }
                }
            },

            getAnimateOut( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                console.log( this.prevRoute.name, this.$route.name );
                if( this.routePath.indexOf( this.prevRoute.name ) < this.routePath.indexOf( this.$route.name ) )
                {
                    if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                    {
                        tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                    }
                }
                else if( this.prevRoute.name === 'Thanks' && this.$route.name === 'Landing' )
                {
                    if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                    {
                        tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                    }
                }
                else
                {
                    if( typeof this.$refs.currentView.getAnimateOutBack === 'function' )
                    {
                        tl.add( this.$refs.currentView.getAnimateOutBack(), 0 );
                    }
                }
            }
        }
    };
</script>

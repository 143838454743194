import musicList from '@/app/data/musicList';

musicList.forEach( ( item, index ) =>
{
    let missing = 0;
    if( !item.deezer )
    {
        item.deezer = 'https://www.deezer.com/en/playlist/3155776842';
        missing++;
    }
    if( !item.spotify )
    {
        item.spotify = 'https://open.spotify.com/playlist/37i9dQZEVXbMDoHDwVN2tF?si=HYY9cMhuQImELdD6-RTbbQ';
        missing++;
    }
    if( !item.apple_music )
    {
        item.apple_music = 'https://itunes.apple.com/nl/playlist/top-100-wereldwijd/pl.d25f5d1181894928af76c85c967f8f31';
        missing++;
    }
    item.index = index;
    if( missing === 3 )
    {
        item.global = true;
    }
});

export default {
    namespaced: true,

    state: {
        musicList
    },
    getters: {
        get: state =>
        {
            return state.musicList;
        },

        findCountry: state => pSearch =>
        {
            let results = state.musicList.filter( item =>
            {
                return item.country.toLowerCase().indexOf( pSearch.toLowerCase() ) >= 0;
            });

            return results;
        },

        findPlaceId: state => placeId =>
        {
            let results = state.musicList.find( item =>
            {
                return item.placeId === placeId;
            });

            return results;
        },

        getCountry: state => pCountry =>
        {
            let result = state.musicList.find( item =>
            {
                return item.country.toLowerCase() === pCountry.toLowerCase();
            });

            if( !result )
            {
                result = {
                    country: 'Global',
                    beschikbaarheid: 1,
                    num: '',
                    deezer: 'https://nu.nl',
                    spotify: 'https://nu.nl',
                    apple_music: 'https://nu.nl'
                };
            }

            return result;
        }
    },
    mutations: {},
    actions: {}
};

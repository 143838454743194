import Vue from 'vue';
import Vuex from 'vuex';

import music from './modules/music';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        music
    },

    state: {
        country: 'Brazil'
    },

    getters: {
        selectedCountry: state => state.country
    },

    mutations: {
        saveCountry: ( state, payload ) =>
        {
            state.country = payload;
        }
    },

    actions: {
        saveCountry: ( context, payload ) =>
        {
            context.commit( 'saveCountry', payload );
        }
    }
});

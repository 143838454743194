<template>
    <div :class="{ 'main-layout':true, [ ( $route.name ? $route.name.toLowerCase() : '' ) + '-route']: true }">

        <button class="back-button clean" v-if="$route.path !== '/'" @click="routeBack"><span>&#8592;</span> Back</button>

        <h1 class="hereicome">#hereicome</h1>

        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>

    export default {
        mounted()
        {
            // TweenMax.to( '.record', 5, { rotation: 360, repeat: -1, ease: Linear.easeNone });
        },

        methods: {
            routeBack()
            {
                this.$router.go( -1 );
            }
        }
    };
</script>
